import { DateRangeType } from "@/types/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateFilter = ({
  range,
  setRange,
  applyFilter,
  clear,
}: {
  readonly range: readonly DateRangeType[];
  readonly setRange: Dispatch<SetStateAction<readonly DateRangeType[]>>;
  readonly applyFilter: () => void;
  readonly clear: boolean;
}) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);

  useEffect(() => {
    if (shouldFetch || clear) {
      applyFilter();
      setShouldFetch(false);
    }
  }, [shouldFetch, applyFilter, range]);

  const onRangeFocusChange = (item: readonly number[]) => {
    if (item[0] === 0 && item[1] === 0) {
      setShouldFetch(true);
    }
  };

  return (
    <DateRange
      onChange={(item) => setRange([item.selection])}
      months={1}
      ranges={range}
      rangeColors={["#000000", "#aaaaa1", "#ffffff"]}
      minDate={new Date("2022-12-01")}
      maxDate={new Date()}
      editableDateInputs={true}
      moveRangeOnFirstSelection={false}
      showDateDisplay={false}
      direction="horizontal"
      onRangeFocusChange={onRangeFocusChange}
      weekStartsOn="1"
    />
  );
};

export { DateFilter };
