import { renderQueryColumn } from "@/utils/columnRenderers";
import React from "react";

export type ApiURL =
  | "/logs/all-rds-queries"
  | "/logs/all-rds-management"
  | "/logs/all-sqs-management";
export interface TabConfig {
  readonly id: string;
  readonly name: string;
  readonly apiUrl: ApiURL;
  readonly tableColumns: readonly ColumnConfig[];
}

export interface ColumnConfig {
  readonly key: string;
  readonly header: string;
  readonly className?: string;
  // eslint-disable-next-line no-unused-vars
  readonly customRender?: (...args: readonly any[]) => React.ReactNode;
}
/*
-------------
Columns logic
-------------
Account -> What has been done -> Where it was done -> Who has done it -> Status

 */
export const ColumnConfigRdsQueries = [
  { key: "account", header: "Account" },
  { key: "action", header: "Action" },
  {
    key: "query",
    header: "Query",
    className: "max-w-[400px]",
    customRender: renderQueryColumn,
  },
  { key: "resource", header: "Database", className: "max-w-[200px]" },
  { key: "requester", header: "Requester" },
  { key: "approver", header: "Approver" },
  { key: "status", header: "Status" },
] as const;

export const ColumnConfigRdsMgt = [
  { key: "account", header: "Account" },
  { key: "action", header: "Action" },
  { key: "region", header: "Region" },
  { key: "resource", header: "RDS" },
  { key: "requester", header: "Requester" },
  { key: "approver", header: "Approver" },
  { key: "status", header: "Status" },
] as const;

export const ColumnConfigSqsMgt = [
  { key: "account", header: "Account" },
  { key: "action", header: "Action" },
  { key: "region", header: "Region" },
  { key: "resource", header: "SQS" },
  { key: "requester", header: "Requester" },
  { key: "approver", header: "Approver" },
  { key: "status", header: "Status" },
] as const;

export const tabsConfig: readonly TabConfig[] = [
  {
    id: "rds_queries",
    name: "RDS Queries",
    apiUrl: "/logs/all-rds-queries",
    tableColumns: ColumnConfigRdsQueries,
  },
  {
    id: "rds_management",
    name: "RDS Management",
    apiUrl: "/logs/all-rds-management",
    tableColumns: ColumnConfigRdsMgt,
  },
  {
    id: "sqs_management",
    name: "SQS Management",
    apiUrl: "/logs/all-sqs-management",
    tableColumns: ColumnConfigSqsMgt,
  },
  // { id: "aws_sqs", name: "AWS SQS" , apiUrl: "/logs/", tableColumns: ColumnConfig1},
  // { id: "private_bin", name: "Privatebin" , apiUrl: "/logs/", tableColumns: ColumnConfig1},
  // { id: "secret_migration", name: "Secret Migration" , apiUrl: "/logs/", tableColumns: ColumnConfig1},
  // { id: "streaming_platform", name: "Streaming Platform" , apiUrl: "/logs/", tableColumns: ColumnConfig1},
] as const;
