import { Card, CardDescription, CardHeader, CardTitle } from "@/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { useEffect, useRef, useState } from "react";
import { LogsTable } from "./LogsTable";
import { Separator } from "@/ui/separator";
import DatePicker from "./DatePicker";
import { SpreadsheetButton } from "./SpreadsheetButton";
import { sevenDaysAgo } from "@/types/types";
import { convertToStartOfDayUTC } from "@/types/types";
import { Filter } from "./Filter";
import { useAllRdsQueries } from "@/api/hooks";
import { cn } from "@/utils";
import { tabsConfig, TabConfig } from "./TabsConfig";

export const ModuleTabs = () => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isScrolledLeft, setIsScrolledLeft] = useState<boolean>(true);
  const [isScrolledRight, setIsScrolledRight] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TabConfig>({
    apiUrl: tabsConfig[0].apiUrl,
    id: tabsConfig[0].id,
    name: tabsConfig[0].name,
    tableColumns: tabsConfig[0].tableColumns,
  });

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(new Date());

  // const { logs, loading, error } = useAllRdsQueries(selectedTab.apiUrl, startDate, endDate);
  const {
    data: logs,
    isLoading: loading,
    isError: error,
  } = useAllRdsQueries({ apiUrl: selectedTab.apiUrl, startDate, endDate });

  const [dateFilterVisible, setDateFilterVisible] = useState(false);

  const [range, setRange] = useState([
    {
      startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const clear = () => {
    setDateFilterVisible(false);
    setRange([
      {
        startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  const apply = () => {
    setStartDate(new Date(range[0].startDate));
    setEndDate(new Date(range[0].endDate));
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    setDateFilterVisible(false);
  };

  const checkHorizontalScrollPosition = () => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setIsScrolledLeft(scrollLeft === 0);
      setIsScrolledRight(scrollLeft + clientWidth === scrollWidth);
    }
  };

  const scrollTabs = (direction: "left" | "right") => {
    if (tabsRef.current) {
      const scrollAmount = 448; // 2 tabs = 440px + 8px padding so 2 new tabs become visible
      if (direction === "left") {
        tabsRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        tabsRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  // useEffect that checks the horizontal scroll position on h-scroll or resize
  useEffect(() => {
    const currentTabsRef = tabsRef.current;
    if (currentTabsRef) {
      currentTabsRef.addEventListener("scroll", checkHorizontalScrollPosition);
      window.addEventListener("resize", checkHorizontalScrollPosition);
      checkHorizontalScrollPosition();
      return () => {
        currentTabsRef?.removeEventListener(
          "scroll",
          checkHorizontalScrollPosition,
        );
      };
    }
  }, []);

  const tabClassName = `flex items-center flex-1 flex-grow h-full min-w-[220px] rounded-none border-b-2 border-transparent duration-150 hover:border-coal inset-x-32
                        data-[state=active]:scale-102 data-[state=active]:border-black data-[state=active]:text-black data-[state=active]:bg-[#FBFBFB] data-[state=active]:drop-shadow-xl`;
  const uniqueAccounts: readonly string[] = Array.from(
    new Set(logs?.map((log) => log.account) ?? []),
  );
  const uniqueResources: readonly string[] = Array.from(
    new Set(logs?.map((log) => log.resource) ?? []),
  );
  const uniqueRequesters: readonly string[] = Array.from(
    new Set(logs?.map((log) => log.requester) ?? []),
  );
  const uniqueApprovers: readonly string[] = Array.from(
    new Set(logs?.map((log) => log.approver) ?? []),
  );
  //Datetime
  const [accountFilter, setAccountFilter] = useState<readonly string[]>([]);
  const [resourceFilter, setResourceFilter] = useState<readonly string[]>([]);
  const [requesterFilter, setRequesterFilter] = useState<readonly string[]>([]);
  const [approverFilter, setApproverFilter] = useState<readonly string[]>([]);

  const filteredLogs = logs?.filter((log) => {
    const matchesAccount =
      accountFilter.length === 0 || accountFilter.includes(log.account);
    const matchesResource =
      resourceFilter.length === 0 || resourceFilter.includes(log.resource);
    const matchesRequester =
      requesterFilter.length === 0 || requesterFilter.includes(log.requester);
    const matchesApprover =
      approverFilter.length === 0 || approverFilter.includes(log.approver);

    return (
      matchesAccount && matchesResource && matchesRequester && matchesApprover
    );
  });

  return (
    <div className="mt-2 relative">
      <Tabs
        value={selectedTab.id}
        onValueChange={(id) => {
          const selectedTabConfig = tabsConfig.find((tab) => tab.id === id);
          if (selectedTabConfig) {
            setSelectedTab({ ...selectedTabConfig });
          }
        }}
        className="w-full"
        defaultValue="rds_queries"
      >
        <div className="flex justify-between">
          <button
            onClick={() => scrollTabs("left")}
            className={cn(
              "z-40 flex-grow w-6 padding-2 bg-[#FBFBFB] border-b-2 border-transparent hover:border-coal",
              {
                "cursor-not-allowed opacity-50 hover:border-transparent":
                  isScrolledLeft,
                "cursor-pointer duration-150": !isScrolledLeft,
                hidden: isScrolledLeft && isScrolledRight,
              },
            )}
          >
            &lt;
          </button>
          <TabsList
            ref={tabsRef}
            className="flex flex-grow clip-tabs overflow-x-auto no-scrollbar items-center justify-around bg-[#FBFBFB]"
          >
            {tabsConfig.map((tab: TabConfig, index: number) => (
              <>
                <TabsTrigger
                  key={tab.id}
                  value={tab.id}
                  className={tabClassName}
                >
                  {tab.name}
                </TabsTrigger>
                {index < tabsConfig.length - 1 && (
                  <Separator orientation="vertical" />
                )}
              </>
            ))}
          </TabsList>
          <button
            type="button"
            onClick={() => scrollTabs("right")}
            className={cn(
              "z-40 flex-grow w-6 padding-2 bg-[#FBFBFB] border-b-2 border-transparent hover:border-coal",
              {
                "cursor-not-allowed opacity-50 hover:border-transparent":
                  isScrolledRight,
                "cursor-pointer duration-150": !isScrolledRight,
                hidden: isScrolledLeft && isScrolledRight,
              },
            )}
          >
            &gt;
          </button>
        </div>
        <div className="">
          <TabsContent value={selectedTab.id}>
            <Card className="h-full mt-2">
              <CardHeader>
                <span className="flex justify-between items-center px-6">
                  <CardTitle className="flex items-center justify-between gap-8 mb-2">
                    {selectedTab.name} logs
                  </CardTitle>
                  <SpreadsheetButton
                    startDate={convertToStartOfDayUTC(startDate)}
                    endDate={convertToStartOfDayUTC(endDate)}
                    account={accountFilter}
                    requester={requesterFilter}
                    approver={approverFilter}
                    resource={resourceFilter}
                    tabUrl={selectedTab.apiUrl}
                  />
                </span>
                <CardDescription>
                  <div className="h-14 flex items-center gap-4 border-b border-[#052A2D] pb-2">
                    <span className="pl-6">Filter by</span>

                    <div className="mr-6 flex gap-1">
                      <Filter
                        name="account"
                        filterArr={uniqueAccounts}
                        setFilters={setAccountFilter}
                        currentTab={selectedTab.id}
                      />
                      <Filter
                        name="resource"
                        filterArr={uniqueResources}
                        setFilters={setResourceFilter}
                        currentTab={selectedTab.id}
                      />
                      <Filter
                        name="requester"
                        filterArr={uniqueRequesters}
                        setFilters={setRequesterFilter}
                        currentTab={selectedTab.id}
                      />
                      <Filter
                        name="approver"
                        filterArr={uniqueApprovers}
                        setFilters={setApproverFilter}
                        currentTab={selectedTab.id}
                      />
                    </div>
                    <DatePicker
                      visible={dateFilterVisible}
                      setVisible={setDateFilterVisible}
                      range={range}
                      setRange={setRange}
                      clearFilter={clear}
                      applyFilter={apply}
                      currentTab={selectedTab.id}
                    />
                  </div>
                </CardDescription>
                {error ? (
                  <div className="flex justify-center">
                    <p className="ml-4 my-16">Error loading data: {error}</p>
                  </div>
                ) : loading ? (
                  <div className="flex justify-center">
                    <p className="ml-4 my-16">Loading...</p>
                  </div>
                ) : logs?.length ? (
                  <div className="h-[calc(100vh-260px)] overflow-auto">
                    <LogsTable
                      data={filteredLogs ?? []}
                      tableColumns={selectedTab.tableColumns}
                    />
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <p className="ml-4 my-16">No data available.</p>
                  </div>
                )}
              </CardHeader>
            </Card>
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};
