import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui/table";
import { SortAsc, SortDesc } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { LogItem } from "@/types/types";
import { LoaderIcon } from "react-hot-toast";
import { ColumnConfig } from "./TabsConfig";

function unixTimeToEuropeanDate(unixTime) {
  const milliseconds = unixTime * 1000;
  const dateObject = new Date(milliseconds);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  let minutesStr: string = "" + minutes,
    secondsStr: string = "" + seconds;
  if (minutes < 10) minutesStr = "0" + minutes;
  if (seconds < 10) secondsStr = "0" + seconds;

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutesStr}:${secondsStr}`;

  return `${formattedDate} ${formattedTime}`;
}

interface LogsTableProps {
  readonly data: readonly LogItem[];
  readonly tableColumns: readonly ColumnConfig[];
}

const DynamicTableHead = ({
  tableColumns,
}: {
  readonly tableColumns: readonly ColumnConfig[];
}) => {
  return (
    <>
      {tableColumns.map((column) => (
        <TableHead key={column.key} className={column.className}>
          {column.header}
        </TableHead>
      ))}
    </>
  );
};

const DynamicTableCell = ({
  item,
  tableColumns,
}: {
  readonly item: LogItem;
  readonly tableColumns: readonly ColumnConfig[];
}) => (
  <>
    {tableColumns.map((column) => (
      <TableCell key={column.key} className={column.className}>
        {column.customRender
          ? column.customRender(item[column.key], item)
          : item[column.key]}
      </TableCell>
    ))}
  </>
);

export const LogsTable = ({ data, tableColumns }: LogsTableProps) => {
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "asc",
  });

  const limitDelta = 500;
  const initialLimit = 100;
  const [tableLimit, setTableLimit] = useState(initialLimit);

  const sortedData = useMemo(() => {
    if (!data) return []; // Handle the case where data is undefined
    console.log(typeof data);
    console.log(data);
    const sortableData = [...data];
    if (sortConfig.key === "date") {
      sortableData.sort((a, b) => {
        if (sortConfig.direction === "asc") {
          return +a.sortKey - +b.sortKey;
        } else {
          return +b.sortKey - +a.sortKey;
        }
      });
    }
    return sortableData.slice(0, tableLimit);
  }, [data, sortConfig, tableLimit]);

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    // when filters change:
    // - hide all logs again
    // - scroll table to the top
    setTableLimit(initialLimit);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [data]);

  const showMore = () => {
    if (tableLimit <= sortedData.length) {
      setTableLimit((limit) => limit + limitDelta);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <SortAsc className="w-4 h-4 inline-block ml-1" />
      ) : (
        <SortDesc className="w-4 h-4 inline-block ml-1" />
      );
    }
    return null;
  };

  return (
    <>
      <Table className="min-w-full overflow-auto" ref={tableRef}>
        <TableCaption></TableCaption>
        <TableHeader className="border-b sticky top-0 bg-[#fbfbfb] z-10 ">
          <TableHead
            className="cursor-pointer w-[120px]"
            onClick={() => handleSort("date")}
          >
            Date {getIcon("date")}
          </TableHead>
          <DynamicTableHead tableColumns={tableColumns} />
          {/* BORDER ON BOTTOM OF TABLE HEADER. TODO: FIX THIS */}
          {/* <div className="w-full ml-4 absolute bg-gray-200 h-px" /> */}
        </TableHeader>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.sortKey}>
              <TableCell>{unixTimeToEuropeanDate(item.sortKey)}</TableCell>
              <DynamicTableCell item={item} tableColumns={tableColumns} />
            </TableRow>
          ))}
        </TableBody>
        <ShowMoreLogs showMore={showMore} />
      </Table>
      <LoadingMessage loaded={tableLimit} total={data.length} />
    </>
  );
};

const LoadingMessage = ({
  loaded,
  total,
}: {
  readonly loaded: number;
  readonly total: number;
}) => {
  const percent = Math.floor((loaded / total) * 100);
  const progressText = loaded < total ? `${percent}%` : "";
  const isLoading = loaded > total;
  return (
    <div className="flex justify-center items-center my-14">
      {isLoading ? (
        <p>No more logs to display.</p>
      ) : (
        <>
          <p>Loading {progressText}</p> <LoaderIcon className="ml-2" />
        </>
      )}
    </div>
  );
};

const ShowMoreLogs = ({ showMore }: { readonly showMore: () => void }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const elementRefCurrent = elementRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            showMore();
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 },
    );

    if (elementRefCurrent) {
      observer.observe(elementRefCurrent);
    }

    return () => {
      if (elementRefCurrent) {
        observer.unobserve(elementRefCurrent);
      }
    };
  }, [showMore]);

  return <div ref={elementRef} />;
};
