import {
  convertToEndOfDayUTC,
  convertToStartOfDayUTC,
  formatDateToDDMMYYYY,
} from "@/types/types";
import { Button } from "@/ui/button";
import { Tooltip } from "@nexus/lib-react/dist/core/Tooltip";
import { useQuery } from "@tanstack/react-query";
import { getToken } from "@unified-frontend/auth";
import { CloudDownload } from "lucide-react";

const fetchXlsx = async (
  startDate: Date,
  endDate: Date,
  account: readonly string[],
  requester: readonly string[],
  approver: readonly string[],
  resource: readonly string[],
  tabUrl: string,
): Promise<Blob> => {
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const baseURL = BASE_URL + "/api";

  const queryParams = new URLSearchParams({
    limit: "15", // Adjust as needed
    from: parseInt(
      (convertToStartOfDayUTC(startDate).getTime() / 1000).toFixed(0),
    ).toString(),
    to: parseInt(
      (convertToEndOfDayUTC(endDate).getTime() / 1000).toFixed(0),
    ).toString(),
  });

  account.forEach((val) => queryParams.append("account", val));
  requester.forEach((val) => queryParams.append("requester", val));
  approver.forEach((val) => queryParams.append("approver", val));
  resource.forEach((val) => queryParams.append("resource", val));

  const headers: Record<string, string> = {};

  let token = getToken();
  if (!token) {
    token = "";
  }

  headers["Authorization"] = "Bearer " + token;
  const tabUrlNormalized = tabUrl.replace("all-", "/");

  const response = await fetch(
    baseURL +
      `${tabUrlNormalized}/download-spreadsheet?${queryParams.toString()}`,
    {
      method: "GET",
      headers: headers,
    },
  );
  if (!response.ok) {
    throw new Error("Failed to fetch XLSX");
  }
  return response.blob(); // Convert response to a Blob (binary data)
};

interface SpreadsheetButtonProps {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly account: readonly string[];
  readonly requester: readonly string[];
  readonly approver: readonly string[];
  readonly resource: readonly string[];
  readonly tabUrl: string;
}

export const SpreadsheetButton = ({
  startDate,
  endDate,
  account,
  requester,
  approver,
  resource,
  tabUrl,
}: SpreadsheetButtonProps) => {
  const { isFetching, refetch } = useQuery<Blob>({
    queryKey: [
      "downloadXlsx",
      startDate,
      endDate,
      account,
      requester,
      approver,
      resource,
      tabUrl,
    ],
    queryFn: () =>
      fetchXlsx(
        startDate,
        endDate,
        account,
        requester,
        approver,
        resource,
        tabUrl,
      ),
    enabled: false, // Disable automatic fetching on component mount
  });

  const downloadXlsx = () => {
    refetch().then((res) => {
      if (res.data) {
        const url = window.URL.createObjectURL(res.data); // Create an object URL from the Blob
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `logs-${formatDateToDDMMYYYY(startDate)}-${formatDateToDDMMYYYY(endDate)}.xlsx`,
        ); // Set the file name for download
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to trigger download
        link.remove(); // Clean up the link element
      }
    });
  };

  return (
    <span>
      <Tooltip content="Download as XLSX" trigger="mouseenter" placement="left">
        <Button
          onClick={downloadXlsx}
          disabled={isFetching || !startDate || !endDate}
          className="flex items-center rounded bg-white hover:border-black border hover:bg-white text-coal transition-all duration-150 hover:ring-gray-700 hover:ring hover:ring-inset"
        >
          <CloudDownload className="w-6 h-6 shrink-0" />
        </Button>
      </Tooltip>
    </span>
  );
};
