import { HoverCard, HoverCardTrigger, HoverCardContent } from "@/ui/hover-card";

export const renderQueryColumn = (value: string) => (
  <HoverCard>
    <HoverCardTrigger className="flex items-center">
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
        {value}
      </div>
    </HoverCardTrigger>
    <HoverCardContent className="min-w-[500px] break-words whitespace-pre-wrap">
      {value}
    </HoverCardContent>
  </HoverCard>
);
