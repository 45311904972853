import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { DateFilter } from "@/ui/calendar";
import { Calendar as CalendarMonth } from "lucide-react";
import { DateRangeType, formatDateToDDMMYYYY } from "@/types/types";

interface DatePickerProps {
  readonly range: readonly DateRangeType[];
  readonly setRange: Dispatch<SetStateAction<readonly DateRangeType[]>>;
  readonly clearFilter: () => void;
  readonly applyFilter: () => void;
  readonly visible: boolean;
  readonly setVisible: Dispatch<SetStateAction<boolean>>;
  readonly currentTab: string;
}

const DatePicker = ({
  range,
  setRange,
  clearFilter,
  applyFilter,
  visible,
  setVisible,
  currentTab,
}: DatePickerProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const [shouldClear, setShoulClear] = useState<boolean>(false);

  useEffect(() => {
    if (visible && modalRef.current) {
      modalRef.current.focus();
    }
  }, [visible]);

  useEffect(() => {
    handleClear();
  }, [currentTab]);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setVisible(false);
    }
  };

  const openFilter = () => {
    setVisible(true);
  };
  const handleClear = () => {
    setShoulClear(true);
    clearFilter();
    setTimeout(() => setShoulClear(false), 0); // Reset clear status after a brief moment
  };

  return (
    <div>
      <div
        className="flex gap-2 cursor-pointer"
        onClick={openFilter}
        data-testid="date-picker-opener"
      >
        <CalendarMonth className="mt-0.5" />
        {range && range.length === 1 && (
          <>
            <div className="p-1 bg-sbbg rounded-lg font-semibold tracking-wide">
              {formatDateToDDMMYYYY(range[0].startDate)}
            </div>
            <div className="mt-1">-</div>
            <div className="p-1 bg-sbbg rounded-lg font-semibold tracking-wide">
              {formatDateToDDMMYYYY(range[0].endDate)}
            </div>
          </>
        )}
      </div>
      <div
        ref={modalRef}
        tabIndex={-1}
        className={`absolute top-[175px] z-30 ${visible ? "scale-100" : "scale-95 opacity-0 pointer-events-none"} transition-all`}
        data-testid="date-filter"
        onClick={(event) => event.stopPropagation()}
        onBlur={handleBlur}
      >
        <div className="shadow-lg text-dark bg-white md:-ml-16 z-0 -mt-5 border">
          <div className="flex justify-center">
            <DateFilter
              range={range}
              setRange={setRange}
              applyFilter={applyFilter}
              clear={shouldClear}
            />
          </div>
          <div className="flex px-4 pb-4 gap-4 justify-end">
            <div
              className=" hover:bg-gray-200 rounded px-4 py-2 tracking-wide transition-all cursor-pointer"
              onClick={handleClear}
            >
              Clear
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
