import {
  convertToEndOfDayUTC,
  convertToStartOfDayUTC,
  RdsResponse,
} from "@/types/types";
import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedClient } from "@unified-frontend/auth";
import { AxiosInstance } from "axios";

async function fetchAllRdsQueries(
  client: AxiosInstance,
  params: {
    readonly apiUrl: string;
    readonly startDate: Date;
    readonly endDate: Date;
  },
) {
  const queryParams = {
    from: parseInt(
      (convertToStartOfDayUTC(params.startDate).getTime() / 1000).toFixed(0),
    ).toString(),
    to: parseInt(
      (convertToEndOfDayUTC(params.endDate).getTime() / 1000).toFixed(0),
    ).toString(),
  };
  const response = await client.get<RdsResponse>(params.apiUrl, {
    params: queryParams,
  });
  return response.data.items;
}

type UseAllRdsQueriesParams = {
  readonly apiUrl: string;
  readonly startDate: Date;
  readonly endDate: Date;
};

export function useAllRdsQueries({
  apiUrl,
  endDate,
  startDate,
}: UseAllRdsQueriesParams) {
  const client = useAuthenticatedClient();
  return useQuery({
    queryKey: [apiUrl, startDate, endDate, client],
    queryFn: () => fetchAllRdsQueries(client, { apiUrl, startDate, endDate }),
    gcTime: 0,
    staleTime: 0,
  });
}
