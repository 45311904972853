import {
  AuthConfiguration,
  dummyProvider,
  validateAndGetAuthProvidersIds,
  oktaProviderFactory,
} from "@unified-frontend/auth";

export function getAvailableProviders(authConfig: AuthConfiguration) {
  const availableProviders = validateAndGetAuthProvidersIds(
    import.meta.env.VITE_ALLOWED_AUTH_PROVIDERS,
  );
  return [
    dummyProvider,
    oktaProviderFactory({
      authConfiguration: authConfig,
      oktaConfig: {
        clientId: import.meta.env.VITE_CLIENT_ID,
        issuer: import.meta.env.VITE_ISSUER,
      },
    }),
  ].filter((provider) => availableProviders.includes(provider.meta.id));
}
