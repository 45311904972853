import { AuthenticatedApiClient } from "@unified-frontend/auth";
import axios from "axios";

export function apiClientFactory(
  baseURL: string,
  loginUrl: string,
): AuthenticatedApiClient {
  const client = axios.create({
    baseURL: baseURL + "/api",
  });
  let token: string | null = null;
  client.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // TODO: redirect to latest location
        // redirector.store(window.location.pathname + window.location.search);
        window.location.replace(loginUrl);
      }
      throw error;
    },
  );
  return {
    client,
    onChangeToken: (newToken: string) => {
      token = newToken;
    },
  };
}
