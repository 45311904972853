import { PropsWithChildren } from "react";
import { Provider } from "@nexus/lib-react/dist/core/Provider";
import { theme } from "@nexus/lib-react/dist/theme/CoreTheme";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { Toaster as SonnerToaster } from "sonner";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { DefaultErrorBoundary } from "./components/DefaultErrorBoundary";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Layout } from "./pages/Layout";
import { LogsPage } from "./pages/LogsPage";

import {
  AuthConfiguration,
  AuthProvider,
  getAuthRoutes,
  RequiredAuth,
} from "@unified-frontend/auth";
import { apiClientFactory } from "./api";
import { getAvailableProviders } from "./auth";
import { AuthHeader } from "@unified-frontend/ui";

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      retry: () => false,
      staleTime: 1000 * 60,
    },
  },
});
const authConfig: AuthConfiguration = {
  url: {
    home: "/home",
    login: "/",
    loginProvider: "/login/provider/:provider",
    loginCallback: "/login/callback/:provider",
    logout: "/logout",
  },
};
const apiClient = apiClientFactory(
  import.meta.env.VITE_BASE_URL,
  authConfig.url.login,
);
const authProviders = getAvailableProviders(authConfig);

const router = createBrowserRouter([
  {
    element: (
      <AuthProvider
        providers={authProviders}
        config={authConfig}
        apiClient={apiClient}
        header={<AuthHeader variant="cora" />}
      />
    ),
    ErrorBoundary: DefaultErrorBoundary,
    children: [
      ...getAuthRoutes(authConfig),
      {
        element: <RequiredAuth />,
        path: "/home",
        children: [
          {
            element: <Layout />,
            children: [
              {
                path: "",
                element: <Navigate to="logs" replace />,
                index: true,
              },
              {
                path: "logs",
                element: <LogsPage />,
              },
            ],
          },
        ],
      },
    ],
  },
] as const);

export const MainWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Provider theme={theme}>
      <Toaster />
      <SonnerToaster richColors />
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </Provider>
  );
};

function App() {
  return (
    <MainWrapper>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </MainWrapper>
  );
}

export default App;
